import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import App from "./App";
import reactToWebComponent from "react-to-webcomponent";

const WebGreeting = reactToWebComponent(App, React, ReactDOM);

customElements.define("chat-widget", WebGreeting);

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
